import service from '@/utils/request'

// zql

// 获取小区物业费排行榜
export const propertyFeeRanking = (params) => {
  return service({
    url: '/dashboard/admin/property-fee-ranking',
    method: 'get',
    params
  })
}

// 获取小区物业费变化趋势
export const propertyFeeVariationTrend = (params) => {
  return service({
    url: '/dashboard/admin/property-fee-variation-trend',
    method: 'get',
    params
  })
}

// 获取工单完成率
// export const orderCompletionRate = (params) => {
//   return service({
//     url: '/dashboard/admin/order-completion-rate?date=month&date=year',
//     method: 'get',
//     params
//   })
// }

// 获取物业服务
export const propertyService = (params) => {
  return service({
    url: '/dashboard/admin/property-service',
    method: 'get',
    params
  })
}

// 获取近半年考勤人数
export const attendance = (params) => {
  return service({
    url: '/dashboard/admin/attendance',
    method: 'get',
    params
  })
}

// 获取管家满意度
export const stewardSatisfaction = (params) => {
  return service({
    url: '/dashboard/admin/steward-satisfaction',
    method: 'get',
    params
  })
}

// 获取小区管家详情
export const stewardInfo = (params) => {
  return service({
    url: '/dashboard/admin/community-steward/'+params.id,
    method: 'get',
    params
  })
}

// 获取小区信息
export const getCommunityInfo = (params) => {
  return service({
    url: '/dashboard/admin/community-info',
    method: 'get',
    params
  })
}

// 获取任务数量 deptId date
export const taskCount = (params) => {
  return service({
    url: '/dashboard/admin/task-num',
    method: 'get',
    params
  })
}

// 获取用户注册分析
export const userRegisterAnalysis = (params) => {
  return service({
    url: '/dashboard/admin/user-register-analysis',
    method: 'get',
    params
  })
}

// 获取业主工单统计
export const ownerOrderStatistics = (params) => {
  return service({
    url: '/dashboard/admin/owner-order-statistics',
    method: 'get',
    params
  })
}

// 获取物业费
export const propertyFee = (params) => {
  return service({
    url: '/dashboard/admin/property-fee',
    method: 'get',
    params
  })
}

// 获取考勤情况分析
export const attendanceAnalysis = (params) => {
  return service({
    url: '/dashboard/admin/attendance-analysis',
    method: 'get',
    params
  })
}

export const createCommunityInfo = (data) => {
  return service({
    url: '/dashboard/admin/community-info',
    method: 'post',
    data
  })
}

export const updateCommunityInfo = (data) => {
  return service({
    url: '/dashboard/admin/community-info',
    method: 'put',
    data
  })
}

export const deleteCommunityInfo = (data) => {
  return service({
    url: '/dashboard/admin/community-info',
    method: 'delete',
    data
  })
}

// lss
// 事项公示 get请求  deptId 
export const getNotice = (params) => {
    return service({
        url: '/owner/dashboard/queryNoticeBy',
        method: 'get',
        params
    })
}
  
// 查询服务团队、今日值班  get请求
export const getServiceTeam = (params) => {
    return service({
        url: '/owner/dashboard/queryServiceTeam',
        method: 'get',
        params
    })
}

export const getOrderAndTask = (params) => {
  return service({
      url: '/owner/dashboard/queryOrderAndTask',
      method: 'get',
      params
  })
}

// 工单完成率, 传参 date,月传month  年传year
export const orderCompletionRate = (params) => {
  return service({
      url: '/dashboard/admin/order-completion-rate',
      method: 'get',
      params
  })
}



// 核心服务, 传参 type,巡检 1 维保2 保洁3 绿化4 安保5  
export const CoreServices = (params) => {
  return service({
      url: '/owner/dashboard/queryTaskImages',
      method: 'get',
      params
  })
}


// 获取地图坐标信息
export const Getprovince = (params) => {
  return service({
    url: 'owner/dashboard/queryNationalCommunity',
    method: 'get',
    params
  })
}

// 任务完成量 传参 date,月传month  年传year 获取任务的数量  @RequestParam("deptId") Long deptId,---- 小区ID @RequestParam("date") String date  (today、month 、year) 
export const taskCompletionRate = (params) => {
  return service({
      url: '/dashboard/admin/task-num',
      method: 'get',
      params
  })
}