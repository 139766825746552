import axios from 'axios' // 引入axios

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH + process.env.VUE_APP_BASE_API,
  timeout: 99999
})

// http request 拦截器
service.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json',
    }
    return config
  },
  error => {
    console.log(error);
    return error
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code === 0 || response.headers.success === 'true') {
      return response.data
    } else {
      // console.log( response.data.msg || decodeURI(response.headers.msg) )
      return response.data.msg ? response.data : response
    }
  },
  error => {
    console.log(error);
    return error
  }
)

export default service